import { Injectable } from '@angular/core';
// import { ChatDB } from '../data/chat/chat';
// import { ChatUsers, chat } from '../model/chat.model';
import { Observable, Subscriber } from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
var today = new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {

  public observer: Subscriber<{}>;
 

  constructor(private http: HttpClient) { }

  /*------------------START GET METHODE-------------------*/ 

   // get type intervention
   getAuth(): Observable<any>{
    return this.http.get(environment.apiUrl + '');
   }
   resetPasssword(item): Observable<any>{
    return this.http.put(environment.apiUrl + 'auth/reset-password' , item);
   }



  



  

}
