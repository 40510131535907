import { Component, OnInit } from '@angular/core';
import { OperationService } from 'src/app/shared/services/http/operation/operations.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIf } from '@angular/common';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

 
  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
  totaux:any = {
    "nombre_interventions" : "",
    "nombre_alerte_motivees" : "",
    "nombre_victimes" : ""
  }

  constructor(private dataservice:OperationService,private router : Router) {
    
  }

  ngOnInit() {
    this.getDashboardData()
  }
  goToLogin(){
    //console.log('redirect');
    this.router.navigate(['auth/login']);
  }
  getDashboardData(){
    
    this.dataservice.getDashboardData().subscribe((response) => {
      this.totaux = response.data;
      //console.log(this.totaux)
    })
  }
 

}
