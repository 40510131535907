<div class="page-wrapper">
	<div class="auth-bg">
		<div class="authentication-box">
			<div class="text-center"><img class="w-25"  src="assets/images/sapeur_pompier2.png" alt=""></div>
			<div class="text-center fw-bold txt-danger">GNSP</div>

			<div class="card mt-4">
				<div class="card-body">
					<div class="text-center">
						<h4>Se connecter</h4>
						<h6>Entrez votre nom d'utilisateur et votre mot de passe </h6>
					</div>
					<form class="theme-form" [formGroup]="loginForm" *ngIf="!newUser">
						<div class="form-group">
							<label class="col-form-label pt-0">Adresse E-mail</label>
							<input class="form-control" formControlName="email" type="email" required="" maxlength="30" placeholder="votreadresse@email.com">
							<!-- <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
								class="text text-danger mt-1">
								L'adresse e-mail est obligatoire
							</div>
							<div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
								class="text text-danger mt-1">
								Email non valide
							</div> -->
						</div>
						<div class="form-group">
							<label class="col-form-label">Mot de passe</label>
							<div class="row">
								<div class="col-md-10 input-group">
									<input class="form-control " type="{{getInputType()}}" formControlName="password" required="" maxlength="30" placeholder="Entrez votre mot de passe">
									<div class="input-group-append" (click)="tooglePasswordView()">

										<div class="input-group-text">
											<span class="{{getIconValue()}}"></span>
										</div>
									</div>
					  
								</div>
							</div>
						</div>
						<div class="form-group row mt-3 mb-0">
							<button *ngIf="!isLoading" class="btn btn-primary btn-block btn-lg"
								[class.loader--text]="authService.showLoader"
								[disabled]="!loginForm.valid || authService.showLoader" (click)="login(loginForm.value)"
								type="submit"><span>{{ authService.showLoader ? '' : 'Connexion' }}</span>
							</button>
						</div>
						<div class="form-group row mt-3 mb-0" *ngIf = "isLoading === true">
							<button class="btn btn-primary" type="button" disabled>
								<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
								Chargement...
							  </button>
						</div>
						<!-- <div class="login-divider"></div> -->
						<div class="social mt-3">
							<!-- <div class="form-group btn-showcase d-flex">
								<button class="btn social-btn btn-fb d-inline-block" (click)="loginFacebook()">
									<i class="fa fa-facebook"></i>
								</button>
								<button class="btn social-btn btn-twitter d-inline-block" (click)="loginTwitter()">
									<i class="fa fa-twitter"></i>
								</button>
								<button class="btn social-btn btn-google d-inline-block" (click)="loginGoogle()">
									<i class="fa fa-google"></i>
								</button>
							</div> -->
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>