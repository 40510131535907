<!-- Navbar -->
<!-- <nav class="navbar navbar-expand-lg navbar-light bg-light">
	
	<div class="container-fluid">
	  <button
		class="navbar-toggler"
		type="button"
		data-mdb-toggle="collapse"
		data-mdb-target="#navbarSupportedContent"
		aria-controls="navbarSupportedContent"
		aria-expanded="false"
		aria-label="Toggle navigation"
	  >
		<i class="fas fa-bars"></i>
	  </button>
  
	  <div class="collapse navbar-collapse " id="navbarSupportedContent">
		<div class="navbar-brand " >
		  <img
			src="assets/images/sapeur_pompier2.png"
			class="img-60 rounded-circle"
			alt="GNSP"
			loading="lazy"
		  />
		</div>
		<p class="text-black text-uppercase">
			GNSP Collect Admin
		</p>
		
	  </div>
  
	  <div class="d-flex align-items-center">
		
		<ul class="navbar-nav me-auto mb-2 mb-lg-0">
			<li class="nav-item bg-warning">
			  <a class="nav-link text-white" href="auth/login">Connexion</a>
			</li>
			
		  </ul>
	  </div>
	</div>
  </nav> -->
  <nav class="navbar navbar-inverse container">
	<div class="container-fluid">
	  <div class="navbar-header">
		<img
			src="assets/images/sapeur_pompier2.png"
			class="img-60 rounded-circle"
			alt="GNSP"
			loading="lazy"
		  />
		  
	  </div>
	  <div >
		<p class="font-weight-bold t fs-3 mt-2" id="title">GNSP COLLECT</p>
	  </div>
	  <!-- <ul class="nav navbar-nav">
		<li class="active"><a href="#">Home</a></li>
		<li><a href="#">Page 1</a></li>
		<li><a href="#">Page 2</a></li>
	  </ul> -->
	  <div class="bg-warning  text-center rounded rounded-3 text-center">
			<button class="nav navbar-nav navbar-right btn btn-warning col-1" (click) ="goToLogin()">Connexion</button>
		
	  </div>
	  
	</div>
  </nav>
  <!-- Navbar -->
<!-- Content -->
<!-- Right elements -->
<main id="main">
	
		
		<!-- <div class="card">
			<ngb-carousel *ngIf="images">
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
						<img src="assets/images/home/intervention11.png" class="img-fluid" alt="Random first slide" />
					</div>
				</ng-template>
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper ">
						<img src="assets/images/home/alerte_motivé.png" class="img-fluid" alt="Random third slide" />
					</div>
				</ng-template>
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
						<img src="assets/images/home/sapeur_intervention.png" class="img-fluid" alt="Random second slide" />
					</div>
				</ng-template>
				
			</ngb-carousel>
		</div> -->
	
	  <!-- <div class="card text-center">
		
		<div class="card-body">
		  <h5 class="card-title"><i class="fa fa-car"></i> Interventions</h5>
		  <p class="card-text" id="value">{{totaux.nombre_interventions}}</p>
		</div>
	  </div> -->
	  <div class="card mb-3">
		
		<div class="card-body">
		  <h5 class="card-title t text-center">PLATEFORME DE SUIVI DES INTERVENTIONS DE SECOURS</h5>
		  <img class="card-img-top" src="assets/images/bann.svg" height="100px" width="50px"  alt="Card image cap">
		  <p class="card-text text-center">
			GNSP Collect permet une meilleure communication, une meilleure coordination et une meilleure gestion des ressources pour les services d'incendie. <br>
			 Il permet ainsi d'optimiser les interventions et d'améliorer la sécurité des sapeurs-pompiers et des citoyens.
		  </p>
		</div>
		
	  </div>
	  <div class="row">
		<div class="col-sm-4">
		  <div class="card text-center">
			<h5 class="card-title t mt-2">  Statistiques</h5>
			<img class="card-img-top" src="assets/images/stat.svg" height="100px" width="50px"  alt="Card image cap">
			<div class="card-body">
			 
			  <p class="card-text " >
				Le volet Statistique de la plateforme  permet aux responsables des services d'incendie de suivre et d'analyser les tendances et les performances
			  </p>
			  
			</div>
		  </div>
		</div>
		<div class="col-sm-4">
		  <div class="card text-center">
			<h5 class="card-title t mt-2"> Paramétrage</h5>
			<img class="card-img-top" src="assets/images/param.svg"  height="100px" width="50px" alt="Card image cap">
			<div class="card-body">
			  
			  <p class="card-text" >
				Le volet Paramétrage de la plateforme  est un outil important pour configurer et personnaliser la plateforme selon les besoins de l'organisation. 
			  </p>
			</div>
		  </div>
		</div>
		<div class="col-sm-4">
			<div class="card text-center">
				<h5 class="card-title t mt-2"> Operations</h5>
			  <img class="card-img-top" src="assets/images/op.svg"   height="100px" width="50px" alt="Card image cap">
			  <div class="card-body">
				
				<p class="card-text" >
					Le volet Opérations permet aux utilisateurs de suivre les interventions, de planifier les interventions, de gérer les incidents et bien plus 
				</p>
			  </div>
			</div>
		  </div>
	  </div>
	
</main>



<!-- Footer Content -->
<footer class=" back text-white text-center" >
	<!-- footer start-->
	<div class="container-fluid ">
		<div class="row">
		<div class="col-md-12 footer-copyright">
			<p class="mb-0">Copyright 2022 © GNSP.</p>
		</div>
		<div class="col-md-6">
			<!-- <p class="pull-right mb-0">Hand crafted & made with<i class="fa fa-heart"></i></p> -->
		</div>
		</div>
	</div>
</footer>


<!-- End footer -->
