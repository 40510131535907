<div class="page-wrapper">
	<div class="auth-bg">
		<div class="authentication-box">
			<div class="text-center"><img class="w-25"  src="assets/images/sapeur_pompier2.png" alt=""></div>
			<div class="text-center fw-bold txt-danger">GNSP</div>

			<div class="card mt-4">
				<div class="card-body">
					<div class="text-center">
						<h4>Modifier le mot de passe</h4>
						<!-- <h6>Entrez votre nom d'utilisateur et votre mot de passe </h6> -->
					</div>
					<form class="" >
						<div class="">
							<label class="col-form-label">Ancien Mot de passe</label>
							<div class="row ">
								<div class="col-md-10 input-group">
									<input class="form-control" type="{{getInputType()}}" [(ngModel)]="item.old_password"  maxlength="30" placeholder="Entrez votre ancien mot de passe">
									<div class="input-group-append" (click)="tooglePasswordView()">

										<div class="input-group-text">
											<span class="{{getIconValue()}}"></span>
										</div>
									</div>
								</div>
								
							</div>
							
						</div>
						<div class=" mt-3">
							<label class="col-form-label">Nouveau mot de passe</label>
							<div class="row ">
								<div class="col-md-10 input-group">
									<input class="form-control" [(ngModel)]="item.password" type="{{getInputType()}}"   maxlength="30" placeholder="Entrez votre nouveau mot de passe">
									<div class="input-group-append" (click)="tooglePasswordView()">

										<div class="input-group-text">
											<span class="{{getIconValue()}}"></span>
										</div>
									</div>
								</div>
							</div>
							
						</div>
						<div class="mt-3">
							<label class="col-form-label">Confirmez Mot de passe</label>
							<div class="row ">
								<div class="col-md-10 input-group">
									<input class="form-control" type="{{getInputType()}}" [(ngModel)]="item.password_confirm"   maxlength="30" placeholder="Confirmez votre mot de passe">
									<div class="input-group-append" (click)="tooglePasswordView()">

										<div class="input-group-text">
											<span class="{{getIconValue()}}"></span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class=" row mt-3 mb-0">
							<button *ngIf="!isLoading" class="btn btn-primary btn-block btn-lg"
								(click)="resetPassword()"
								type="submit">Modifier <i class="fa fa-edit"></i>
							</button>
						</div>
						<div class=" row mt-3 mb-0" *ngIf = "isLoading === true">
							<button class="btn btn-primary" type="button" disabled>
								<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
								Chargement...
							  </button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>