<div class="container">
	<div class="row align-items-center">
		<div class="col-lg-12 col-md-12">
			<div class="text-black">
			   <h1>GNSP</h1>
			   <p>GNSP est éditée par IWAJU TECH, Société Unipersonnelle au capital de 10 000 euros - Cotonou, Bénin.</p>
			   <p>Le terme Application s'applique à l'application pour mobile ou tablette Android et IOS nommée «GNSP Collect».</p>
			   <h1>Données personnelles</h1>
			   <p>Depuis notre Application, nous collectons des données personnelles (par exemple: les noms, les adresses, les numéros de téléphone ,les photos et quelques pièces d'identité).</p>
			   <h1>Permissions demandées par l'Application</h1>
			   <h3>Utilisation de la caméra</h3>
			   <p>Nous utilisons uniquement l'appareil photo pour prendre les photos de profil, nous ne collectons aucune autre information (film).</p>
			   <h3>Utilisation de la galerie</h3>
			   <p>Nous sélectionnons uniquement dans la galerie la photo pour en faire une photo de profil et deux autres fichiers png pour vérification d'identité,nous ne collectons aucune autre information.</p>
			   <h3>Utilisation du répertoire téléphonique</h3>
			   <p>Nous sélectionnons uniquement dans le répertoire téléphonique les numéros de téléphones pour ajouter des participants , nous ne collectons aucune autre information.</p>
			   <h3>Interaction avec d'autres application</h3>
			   <p>Nous partageons l'apk et les collectes créées via d'autres applications.</p>
			   <h1>Données personnelles non collectées automatiquement</h1>
			   <p>Nous collectons uniquement des informations en cas de panne de votre mobile lors de l'utilisation de l'application. Ces informations sur le crash, qui sont nécessaires au débogage de l'Application, sont envoyées au site tiers FireBase. Les informations suivantes sont collectées :</p>
			   <ul>
					<li>Le type d'appareil utilisé,</li>
					<li>Quelle version d'Android ou IOS est utilisée sur l'appareil,</li>
					<li>L'opérateur mobile,</li>
					<li>Quelle connexion était active (WiFi ou réseau mobile),</li>
					<li>Le crash-log décrivant dans quelle section du code le crash s'est produit.</li>
			   </ul>
			   <p>Nous collectons des informations personnelles identifiables. Nous ne vendons pas, n'échangeons ni transférons ces informations à des tiers. Cependant, Becca s'appuie sur des logiciels fournis par d'autres éditeurs ; leurs règles de confidentialité sont aussi applicables.</p>

			  <h3>Email : gnspcollect@gmail.com</h3>
			  <h3>WhatsApp :+229 63 39 99 96</h3>
			</div>
		</div>
	</div>
</div>