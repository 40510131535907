export class PasswordReset {
    reference?:string
    old_password?: string
    password?: string
    password_confirm?: string
  
    private errorMessage: string;
  
    constructor(){
      this.errorMessage = "";
    }
    

    //get error message
  getErrorMessage(): string {
    return this.errorMessage;
  }//end getErrorMessage
    canSave(): boolean {
    
        //old password
        if(this.old_password === undefined || this.old_password === null ){
          this.errorMessage = "Veuillez saisir votre ancien mot de passe"; return false;
        }
      
        //new password
        if(this.password === undefined || this.password === null ){
        this.errorMessage = "Veuillez saisir votre nouveau mot de passe"; return false;
        }

        //new password
        if(this.password_confirm === undefined || this.password_confirm === null ){
            this.errorMessage = "Veuillez confirmez votre nouveau mot de passe"; return false;
        }

        //check both password
        //new password
        if(this.password != this.password_confirm  ){
            this.errorMessage = "Les deux mots de passe ne correspondent pas"; return false;
        }
      return true
    }


}