import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
    // data: {
    //   breadcrumb: "Dashboard"
    // }
  },
  {
    path: 'operations',
    loadChildren: () => import('../../components/operations/ui-elements.module').then(m => m.UiElementsModule),
    data: {
      breadcrumb: "Paramètres"
    }
  },
  {
    path: 'parametres',
    loadChildren: () => import('../../components/parametres/parametres.module').then(m => m.ParametresModule),
    data: {
      breadcrumb: "Paramètres"
    }
  },
  {
    path: 'attributions',
    loadChildren: () => import('../../components/attributions/attributions.module').then(m => m.AttributionsModule),
    data: {
      breadcrumb: "Attributions"
    }
  },
  {
    path: 'statistiques',
    loadChildren: () => import('../../components/statistiques/statistiques.module').then(m => m.StatistiqueModule),
    data: {
      breadcrumb: "Statistiques"
    }
  },
  
];