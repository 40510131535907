import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment'
import { Item } from 'angular2-multiselect-dropdown';
var today = new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  public observer: Subscriber<{}>;
 

  constructor(private http: HttpClient) { }

  //victime
    // get victime menu operation
    getVictime(): Observable<any>{
      return this.http.get( environment.apiUrl + 'params/listing-victime');
    }
    //create victime
    addVictime(item): Observable<any>{
      return this.http.post( environment.apiUrl + '' , item);
    }
    //delete intervention
    deleteVictime(item): Observable<any>{
      return this.http.post( environment.apiUrl + '' , item);
    }
    //edit intervention
    editVictime(item): Observable<any>{
      return this.http.post( environment.apiUrl + '' , item);
    }
    
  //end victime

  //intervention
    // get intervention
    getIntervention(item): Observable<any>{
      return this.http.post( environment.apiUrl + 'interventions/admin/dashboard/interventions' , item);
    }

    //get intervention en attente
    getInterventionAttente(item): Observable<any>{
      return this.http.post( environment.apiUrl + 'interventions/admin/dashboard/interventions/nonvalide',item );
    }
    // create intervention
    addIntervention(item): Observable<any>{
      return this.http.post( environment.apiUrl + 'interventions/save-intervention' , item);
    }
    //delete intervention
    deleteIntervention(item): Observable<any>{
      return this.http.post( environment.apiUrl + 'interventions/remove-intervention' , item);
    }
    //edit intervention
    editIntervention(item): Observable<any>{
      return this.http.post( environment.apiUrl + '' , item);
    }
    //validate intervention
    validateIntervention(item): Observable<any>{
      return this.http.post( environment.apiUrl + 'interventions/validated-intervention' , item);
    }


    //get alerte motivée params/typealertemotivees
    getAlerteMotive(): Observable<any>{
      return this.http.get( environment.apiUrl + 'params/typealertemotivees');
    }
  //end intervention 

  //get data dashboard
  getDashboardData(): Observable<any>{
    return this.http.get( environment.apiUrl + 'interventions/dashboard' );
  }

  getInterventionByDate(item): Observable<any>{
    return this.http.post( environment.apiUrl + 'interventions/get-intervention-by-date' , item);
  }

  getInterventionValidateByDate(item): Observable<any>{
    return this.http.post( environment.apiUrl + 'interventions/admin/dashboard/interventions/nonvalidé' , item);
  }
}
