import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgIf } from '@angular/common';
@Component({
  selector: 'app-home',
  templateUrl: './politique_confidentialite.component.html',
  styleUrls: ['./politique_confidentialite.scss']
})
export class PolitiqueComponent implements OnInit {

 
  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
  totaux:any = {
    "nombre_interventions" : "",
    "nombre_alerte_motivees" : "",
    "nombre_victimes" : ""
  }

  constructor(private router : Router) {
    
  }

  ngOnInit() {
    
  }
 

}
